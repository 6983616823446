@import '~@atlaskit/util-shared-styles/src/fonts';
@import 'colors';

@aui-text-color: @ak-color-N800;
@aui-font-family: @ak-font-family;
@aui-font-family-ja: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", Verdana, Arial, sans-serif;

// Fonts and line heights
@aui-font-size-xxxlarge: 32px;
@aui-font-size-xxlarge: 24px;
@aui-font-size-xlarge: 20px;
@aui-font-size-large: 16px;
@aui-font-size-medium: 14px;
@aui-font-size-small: 12px;
@aui-font-size-xsmall: 11px;

@aui-font-letter-spacing: 0;
