@import './imports/global';

/**
 * Dropdown 2
 */

/* Functional styles - these are always applied
---------------------------------------------------------------------- */
.aui-dropdown2 {
    box-sizing: border-box;
    max-width: 300px;
    min-width: 160px;
    position: absolute;
    &[aria-hidden="true"] {
        top: -999em;
        left: -999em;
    }
    &:not([resolved]) {
        display: none;
    }
}

.aui-dropdown2 [role="menuitem"],
.aui-dropdown2 [role="menuitemcheckbox"],
.aui-dropdown2 [role="menuitemradio"],
.aui-dropdown2 [role="radio"],
.aui-dropdown2 [role="checkbox"],
.aui-dropdown2 a {
    #aui.prevent-text-selection();
    display: block;
}

.aui-dropdown2 .aui-list-truncate [role="menuitem"],
.aui-dropdown2 .aui-list-truncate [role="menuitemcheckbox"],
.aui-dropdown2 .aui-list-truncate [role="menuitemradio"],
.aui-dropdown2 .aui-list-truncate [role="radio"],
.aui-dropdown2 .aui-list-truncate [role="checkbox"],
.aui-dropdown2 .aui-list-truncate a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Default style for Dropdown2, invoked with aui-style-default
---------------------------------------------------------------------- */
.aui-dropdown2.aui-style-default {
    background-color: @aui-dropdown2-bg-color;
    border: 1px solid @aui-dropdown2-border-color;
    border-bottom-left-radius: @aui-dropdown2-border-radius;
    border-bottom-right-radius: @aui-dropdown2-border-radius;
    box-shadow: @aui-dropdown2-box-shadow;

    &.aui-layer { // To override the specific height calculations for layer.
        -webkit-height: auto; // Will be handled by JS
        height: auto; // Will be handled by JS
    }
}

.aui-dropdown2.aui-style-default ul {
    list-style: none;
    margin: 2px 0;
    padding-left: 0;
}

.aui-style-default .aui-dropdown2-section + .aui-dropdown2-section {
    border-top: 1px solid @aui-dropdown2-border-color;
}

.aui-dropdown2.aui-style-default > strong,
.aui-dropdown2.aui-style-default .aui-dropdown2-section > strong,
.aui-dropdown2-heading {
    color: #707070;
    display: block;
    font-size: 12px;
    font-weight: bold;
    padding: 7px 10px 5px;
    text-transform: uppercase;
}

.aui-dropdown2.aui-style-default strong + ul {
    margin-top: 0;
}

.aui-dropdown2.aui-style-default [role="menuitem"],
.aui-dropdown2.aui-style-default [role="menuitemcheckbox"],
.aui-dropdown2.aui-style-default [role="menuitemradio"],
.aui-dropdown2.aui-style-default [role="radio"],
.aui-dropdown2.aui-style-default [role="checkbox"],
.aui-dropdown2.aui-style-default a {
    color: @aui-text-color;
    padding: 3px 10px;
    text-decoration: none;
}

.aui-dropdown2.aui-style-default {
    [aria-disabled="true"],
    a.disabled {
        color: @aui-dropdown2-disabled-text-color;
        cursor: default;
    }
}

.aui-dropdown2.aui-style-default a:focus,
.aui-dropdown2.aui-style-default a:hover {
    text-decoration: none;
}

.aui-dropdown2.aui-style-default a:focus {
    z-index: 1;
}

.aui-dropdown2.aui-style-default .aui-dropdown2-radio:not([aria-disabled="true"]),
.aui-dropdown2.aui-style-default .aui-dropdown2-checkbox:not([aria-disabled="true"]) {
    cursor: pointer;
}

.aui-dropdown2.aui-style-default .aui-icon-container,
.aui-dropdown2.aui-style-default .aui-dropdown2-radio,
.aui-dropdown2.aui-style-default .aui-dropdown2-checkbox {
    padding-left: 31px;
}

.aui-dropdown2.aui-style-default .aui-icon-container,
.aui-dropdown2.aui-style-default .aui-dropdown2-radio,
.aui-dropdown2.aui-style-default .aui-dropdown2-checkbox {
    /* position of the background or icon (both 16x16) is:
     * - 10px from the left to match the item padding
     * - 7px from the top which is calculated:
     *   - 5px for the top padding of the item
     *   - 2px is half the difference between the line-height (20) and the icon height (16) so it's vertically spaced
     */
    background-position: 10px 5px;
    background-repeat: no-repeat;
    position: relative;
}

.aui-dropdown2.aui-style-default .aui-icon-container > img,
.aui-dropdown2.aui-style-default .aui-icon-container > .aui-icon,
.aui-dropdown2.aui-style-default .aui-icon-container > .aui-avatar {
    border-width: 0;
    left: 10px;
    position: absolute;
    top: 5px;
}

.aui-dropdown2.aui-style-default .aui-icon-container > .aui-icon {
    display: block;
    overflow: hidden;
    text-indent: -99999px;
}

/* Checkbox items */
.aui-dropdown2.aui-style-default .aui-dropdown2-checkbox.aui-dropdown2-checked {
    #aui.icon(@aui-glyph-check, {
        margin-left: (@aui-icon-size-small / 2);
    });
}

/* Radio items */
.aui-dropdown2.aui-style-default .aui-dropdown2-radio.aui-dropdown2-checked {
    #aui.icon(@aui-glyph-radio, {
        margin-left: (@aui-icon-size-small / 2);
    });
}

.aui-dropdown2.aui-dropdown2-tailed {
    border: 1px solid @aui-dropdown2-border-color;
    margin-top: 10px;
}

.aui-dropdown2.aui-dropdown2-tailed::before,
.aui-dropdown2.aui-dropdown2-tailed::after {
    border-color: transparent;
    border-style: outset outset solid outset;
    border-width: 8px;
    bottom: 100%;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    width: 0;
}

.aui-dropdown2.aui-dropdown2-tailed::before {
    border-bottom-color: @aui-dropdown2-border-color;
    margin-bottom: 1px;
}

.aui-dropdown2.aui-dropdown2-tailed::after {
    border-bottom-color: @aui-dropdown2-bg-color;
}

/* basic in-page dropdown trigger style DEPRECATED as of 4.2 */
.aui-dropdown2-trigger.aui-style-dropdown2triggerlegacy1 {
    padding: 5px 8px;
    text-decoration: none;
}

.aui-dropdown2-trigger.aui-style-dropdown2triggerlegacy1:hover,
.aui-dropdown2-trigger.aui-style-dropdown2triggerlegacy1:focus,
.aui-dropdown2-trigger.aui-style-dropdown2triggerlegacy1.active,
.aui-dropdown2-trigger.aui-style-dropdown2triggerlegacy1.aui-dropdown2-active {
    background-color: #326ca6;
    border-radius: 4px;
    color: @aui-dropdown2-trigger-active-color;
    text-decoration: none;
}

.aui-dropdown2-trigger.aui-style-dropdown2triggerlegacy1.active,
.aui-dropdown2-trigger.aui-style-dropdown2triggerlegacy1.aui-dropdown2active {
    border-radius: 4px 4px 0 0;
}

.aui-dropdown2-trigger.aui-style-dropdown2triggerlegacy1[aria-disabled="true"],
.aui-dropdown2-trigger.aui-style-dropdown2triggerlegacy1[aria-disabled="true"]:hover,
.aui-dropdown2-trigger.aui-style-dropdown2triggerlegacy1[aria-disabled="true"]:focus,
.aui-dropdown2-trigger.aui-style-dropdown2triggerlegacy1[aria-disabled="true"]:active {
    background: inherit;
    color: #99b6d3; /* equivalent of opacity: 0.5 */
    cursor: default;
}

/* Toolbar/Toolbar2-via-buttons integration. Avoid double border: */
.aui-dropdown2.aui-style-default.aui-dropdown2-in-toolbar,
.aui-dropdown2.aui-style-default.aui-dropdown2-in-buttons {
    margin-top: -1px;
    border-top-color: @aui-dropdown2-border-color;
}

/* Header integration. */
.aui-dropdown2.aui-style-default.aui-dropdown2-in-header {
    margin-top: 1px;
}

.aui-dropdown2.aui-style-default.aui-dropdown2-in-header.aui-dropdown2-in-buttons {
    margin-top: 0;
}

.aui-dropdown2.aui-style-default.aui-dropdown2-in-header.aui-dropdown2-tailed {
    margin-top: 4px;
}

/* Arrow for sub menus */
.aui-dropdown2-sub-trigger {
    position: relative;
}

.aui-dropdown2-sub-trigger::after {
    border: 5px solid transparent;
    border-left-color: @aui-dropdown2-trigger-color;
    content: "";
    height: 0;
    margin-left: -16px;
    margin-top: -5px;
    left: 100%; /* "left" + "margin-left" required because of webkit not working properly with "right" */
    position: absolute;
    top: 50%;
    width: 0;
}

.aui-dropdown2-sub-trigger.active::after,
.aui-dropdown2-sub-trigger.aui-dropdown2-active::after {
    border-left-color: @aui-dropdown2-trigger-active-color;
}

.aui-dropdown2-sub-trigger[disabled]::after,
.aui-dropdown2-sub-trigger.disabled::after,
.aui-dropdown2-sub-trigger.aui-dropdown2-disabled::after {
    border-left-color: @aui-dropdown2-trigger-disabled-color;
}

.aui-dropdown2.aui-dropdown2-sub-menu,
.aui-dropdown2.aui-dropdown2-in-header.aui-dropdown2-sub-menu {
    margin-top: -3px;
}

.aui-dropdown2.aui-style-default {
    &:hover {
        //If hovering on the dropdown, set all but the hovered item to inactive display
        .aui-dropdown2-checkbox,
        .aui-dropdown2-radio,
        a {
            background-color: @aui-dropdown2-bg-color;
            color: @aui-text-color;
        }
        .aui-dropdown2-checkbox.aui-dropdown2-disabled,
        .aui-dropdown2-radio.aui-dropdown2-disabled,
        a.aui-dropdown2-disabled {
            color: @aui-dropdown2-disabled-text-color;
        }

        .aui-dropdown2-checkbox:not(.aui-dropdown2-disabled):hover,
        .aui-dropdown2-radio:not(.aui-dropdown2-disabled):hover,
        a:not(.aui-dropdown2-disabled):hover {
            background-color: @aui-link-inverted-bg-color;
            color: @aui-link-inverted-text-color;
        }
    }
    //If not hovering on the dropdown, use the active class for display
    .active,
    .aui-dropdown2-active {
        background-color: @aui-link-inverted-bg-color;
        color: @aui-link-inverted-text-color;
        &.aui-dropdown2-disabled {
            background-color: @aui-dropdown2-disabled-hover-bg-color;
            color: @aui-dropdown2-disabled-text-color;
        }
    }
}

.aui-dropdown2-trigger:not(.aui-dropdown2-trigger-arrowless) {
    padding-right: @aui-icon-size-small-canvas !important; // Someone else was greedy to set padding
    position: relative;
}

/* Dropdown2 button integration
---------- */
.aui-button.aui-dropdown2-trigger.active:first-child,
.aui-button.aui-dropdown2-trigger.active {
    /* square off bottom corners */
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.aui-button-link.aui-dropdown2-trigger.active:first-child,
.aui-button-link.aui-dropdown2-trigger.active,
.aui-button-text.aui-dropdown2-trigger.active:first-child,
.aui-button-text.aui-dropdown2-trigger.active {
    box-shadow: none;
}

.aui-buttons .aui-button.aui-button-subtle.aui-dropdown2-trigger.active,
.aui-button.aui-button-subtle.aui-dropdown2-trigger.active {
    border-color: @aui-button-subtle-border-color;
}

.aui-button.aui-button-subtle.aui-dropdown2-trigger:hover {
    border-color: @aui-button-subtle-dropdown2-trigger-arrow-color;
}

/* Compact Dropdown2 Trigger */
.aui-button.aui-button-compact.aui-dropdown2-trigger:not(.aui-dropdown2-trigger-arrowless) {
    padding-right: 21px !important; /* 8px (arrow width) + 8px (right padding) + 5 (margin between arrow and logo) */
}

.aui-button.aui-button-compact.aui-dropdown2-trigger::after {
    margin-left: -16px; /*  Override the margin-left from below for to compensate for the smaller padding-right */
}

/* adjust padding for smaller height */
.aui-button.aui-dropdown2-trigger.aui-button-compact.active:first-child:not(.aui-dropdown2-trigger-arrowless),
.aui-button.aui-dropdown2-trigger.aui-button-compact.active:not(.aui-dropdown2-trigger-arrowless) {
    padding-bottom: 3px; /* increase the padding to cater for the lack of border so the button doesn't change size */
}

/* End Compact Dropdown2 Trigger */
.aui-button.aui-dropdown2-trigger:not(.aui-dropdown2-trigger-arrowless)::after {
    #aui.aui-dropdown2-trigger-chevron-icon();
}

/* suppress old icon pattern for dropdown widget so we don't get double arrows */
.aui-button.aui-dropdown2-trigger > .icon-dropdown,
.aui-button.aui-dropdown2-trigger > .aui-icon-dropdown {
    display: none;
}

/* INPUT does not play with ::after - dropdown2 does not support arrow styles for INPUT buttons */
/* We cannot position arrows on aui-button-text because we don't know what size they will be all the time */
input.aui-button.aui-dropdown2-trigger,
.aui-button.aui-button-text.aui-dropdown2-trigger {
    padding-right: 10px !important; /* someone used important now we're all doomed */
}

input.aui-button.aui-dropdown2-trigger::after,
.aui-button.aui-button-text.aui-dropdown2-trigger::after {
    display: none;
}

/* Dropdown2-only / Split Button
---------- */
.aui-buttons .aui-button.aui-dropdown2-trigger.aui-button-split-more,
.aui-buttons .aui-button.aui-dropdown2-trigger.aui-button-split-more.active {
    /* don't touch vertical padding or things jump around, jump around, jump up jump up and get down */
    padding-left: 0;
    padding-right: 0;
    width: @aui-icon-size-small-canvas;
    text-indent: -9999em;
}

/**
 * Dropdown2 triggers in header
 */
.aui-header .aui-dropdown2-trigger.active,
.aui-header a:focus,
.aui-header a:hover,
.aui-header a:active {
    color: @aui-header-link-text-color;
    background-color: @aui-header-link-active-bg-color;
}

/* In case showIcon is not set to false for header dropdown triggers */
.aui-header .aui-dropdown2-trigger .aui-icon-dropdown {
    display: none;
}

/* Styling the dropdown2 triggers differently in the header to avoid inline-block spacing issues with other icons */
.aui-header .aui-dropdown2-trigger:not(.aui-dropdown2-trigger-arrowless)::after {
    #aui.aui-dropdown2-trigger-chevron-icon();
}

/**
 * Dropdown2 web components
 */
aui-dropdown-group,
aui-section {
    display: block;
}

aui-dropdown-menu.aui-dropdown2 {
    //Selector copied from above: need this for specificity, want to keep separate from the rest of the DD2 CSS.
    .aui-dropdown2-heading, .aui-style-default .aui-dropdown2-section > strong, .aui-dropdown2.aui-style-default > strong {
        &:empty {
            display: none;
        }
    }
}

// Async dropdown
aui-dropdown-menu {
    &:not([resolved]) {
        display: none;
    }

    .aui-dropdown-loading {
        padding: 5px;

        .spinner {
            display: inline-block;
            margin: 5px;
        }
    }
}
