@import './imports/global';

// AUI Tables
// Note child combinator are used to ensure nested non-AUI tables don't receive any AUI styles.

@aui-border-tables: @aui-border-width @aui-border-type @aui-border-color;

table.aui {
    border-collapse: collapse;
    width: 100%;

    // Table inside a table
    & & {
        margin: 0;
    }

    > caption {
        color: @aui-table-caption-text-color;
        background: @aui-table-caption-bg-color;
        border-bottom: @aui-border-tables;
        caption-side: top;
        padding: @aui-table-padding-y @aui-table-padding-x;
        text-align: @aui-table-text-align;
    }

    > tbody > tr,
    > tfoot > tr {
        background: @aui-table-row-bg-color;
        border-bottom: @aui-border-tables;
        color: @aui-table-row-text-color;
    }

    > tbody > tr > th {
        background: @aui-table-header-bg-color;
        color: @aui-table-heading-text-color;
    }
    > thead > tr > th,
    > tbody > tr > th,
    > thead > tr > td,
    > tbody > tr > td,
    > tfoot > tr > td {
        padding: @aui-table-padding-y @aui-table-padding-x;
        text-align: @aui-table-text-align;
        vertical-align: @aui-table-vertical-align;
    }

    > thead {
        border-bottom: @aui-border-tables;
    }

    > tbody > tr:first-child > td,
    > tbody > tr:first-child > th,
    > tfoot > tr:first-child > td {
        border-top: @aui-border-tables;
    }

    > thead > tr > th > ul.menu,
    > tbody > tr > th > ul.menu,
    > tbody > tr > td > ul.menu,
    > tfoot > tr > td > ul.menu {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    > thead > tr > th > ul.menu > li,
    > tbody > tr > th > ul.menu > li,
    > tbody > tr > td > ul.menu > li,
    > tfoot > tr > td > ul.menu > li {
        float: left;
        margin: 0 10px 0 0;
        width: auto;
    }

    /* Interactive Tables */
    &.aui-table-interactive > tbody > tr:hover {
        background: @aui-table-interactive-row-hover-bg-color;
    }

    /* End Interactive Tables */

    /* stripes - deprecated */
    &.aui-zebra {
        tr {
            border-bottom: 0;
        }

        & > thead > tr:nth-child(even),
        & > tbody > tr:nth-child(even),
        & > tfoot > tr:nth-child(even),
        & > tbody > tr:nth-child(even) > th {
            background: @aui-table-zebra-even-bg-color;
            color: @aui-table-zebra-even-text-color;
        }
    }

    // Buttons
    .aui-button-link {
        padding-top: 0;
        padding-bottom: 0;
        line-height: inherit;
        height: auto;
        border: 0;
    }
}
