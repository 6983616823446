@keyframes spinnerRotateAnimation {
    to {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}
@-webkit-keyframes spinnerRotateAnimation {
    to {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}

.small-spinner-stroke() {
    stroke-dasharray: 56px;
    stroke-dashoffset: 45px;
    stroke-width: 2px;
}

.medium-spinner-stroke() {
    stroke-dasharray: 84px;
    stroke-dashoffset: 67px;
    stroke-width: 3px;
}

.large-spinner-stroke() {
    stroke-dasharray: 141px;
    stroke-dashoffset: 113px;
    stroke-width: 5px;
}

aui-spinner {
    display: block;
    position: relative;
    z-index: 2000000000;

    &[filled] {
        // The "filled" variant of the spinner should always take up no space in the DOM
        height: 0 !important;
        width: 0 !important;
        margin: auto;

        &[size="small"]>.aui-spinner>svg {
            left: -10px;
        }

        &[size="medium"]>.aui-spinner>svg {
            left: -15px;
        }

        &[size="large"]>.aui-spinner>svg {
            left: -25px;
        }
    }

    &[size="small"] {
        height: 20px;
        width: 20px;

        .aui-spinner>svg {
            .small-spinner-stroke();
        }
    }

    &[size="medium"] {
        height: 30px;
        width: 30px;

        .aui-spinner>svg {
            .medium-spinner-stroke();
        }
    }

    &[size="large"] {
        height: 50px;
        width: 50px;

        .aui-spinner>svg {
            .large-spinner-stroke();
        }
    }

    .aui-spinner {
        position: relative;

        &>svg {
            position: absolute;

            animation: 0.86s cubic-bezier(0.4, 0.15, 0.6, 0.85) infinite spinnerRotateAnimation;
            -webkit-animation: 0.86s cubic-bezier(0.4, 0.15, 0.6, 0.85) infinite spinnerRotateAnimation;
            fill: none;
            stroke: #42526E;
            stroke-linecap: round;
            transform-origin: center;
            -ms-transform-origin: center;
            -webkit-transform-origin: center;
        }
    }
}
