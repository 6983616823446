.responsive-max-width(@maxWidth; @rules) {
    @media all and (max-width: @maxWidth) {
        @rules();
    }
}

.responsive-max-height(@maxHeight; @rules) {
    @media all and (max-height: @maxHeight) {
        @rules();
    }
}
